.table-of-contents {
  --link-color: var(--color-dark-blue);

  background-color: var(--color-light-blue);
  line-height: var(--line-height-default);
  padding: 3.2rem;
}

.table-of-contents__headline {
  margin-bottom: 1.6rem;
}

.table-of-contents__list {
  & & {
    margin-top: 0.6rem;
    padding-left: 2.4rem;
  }
}

.table-of-contents__link {
  display: block;

  & + & {
    margin-top: 0.6rem;
  }
}
