@use 'mq';

.pagination {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.6rem;
  justify-content: center;

  @include mq.mq($from: l) {
    flex-wrap: nowrap;
    font-size: 2rem;
  }
}

.pagination__link {
  flex-shrink: 0;
  width: 50%;

  &:last-child {
    text-align: right;
  }

  @include mq.mq($from: l) {
    width: 25%;
  }
}

.pagination__pages {
  display: flex;
  flex-grow: 1;
  margin-top: 3.2rem;
  order: 1;

  @include mq.mq($from: l) {
    margin-top: 0;
    order: 0;
  }
}

.pagination__pages--prev {
  justify-content: flex-end;
}

.pagination__page {
  box-shadow: inset 0 0 0 1px var(--color-black);
  display: inline-block;
  padding: 1.2rem;
  transition-property: background-color, color;

  & + & {
    margin-left: 0.8rem;

    @include mq.mq($from: xl) {
      margin-left: 1.6rem;
    }
  }

  &:hover,
  &:focus {
    background-color: var(--color-black);
    color: var(--color-white);
  }
}

.pagination__current {
  background-color: var(--color-black);
  box-shadow: inset 0 0 0 1px var(--color-black);
  color: var(--color-white);
  display: inline-block;
  flex-shrink: 0;
  font-weight: bold;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  margin-top: 3.2rem;
  order: 1;
  padding: 1.2rem;

  @include mq.mq($from: l) {
    margin-top: 0;
    order: 0;
  }

  @include mq.mq($from: xl) {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
}
