@use 'sass:color';
@use 'mq';

@keyframes showFilterForm {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    opacity: 1;
  }
}

.navigator {
  background-color: var(--color-light-blue);
}

.navigator__tabs {
  background-color: var(--color-white);
  display: none;
  padding-top: 4.8rem;

  @include mq.mq($from: m) {
    display: flex;
    justify-content: center;
  }
}

.navigator__tab {
  display: block;
  font-family: var(--font-stack-dosis);
  font-size: 2.6rem;
  line-height: var(--line-height-small);
  text-transform: uppercase;
}

.navigator__tab-link {
  display: block;
  padding: 1.6rem 2.4rem;
  transition-property: background-color;

  &[aria-selected='true'] {
    background-color: var(--color-light-blue);
  }

  &:focus {
    outline-offset: -3px;
  }

  @include mq.mq($from: l) {
    padding: 2.4rem 3.2rem;
  }

  @include mq.mq($from: xl) {
    padding: 2.4rem 4rem;
  }
}

.navigator__panels {
  border-bottom: 1px solid var(--color-light-blue);
}

.navigator__panel {
  position: relative;
}

.navigator__panel-trigger {
  @include use-container();

  background-color: var(--color-white);
  border-top: 1px solid var(--color-light-blue);
  font-family: var(--font-stack-dosis);
  font-size: 2.6rem;
  line-height: var(--line-height-small);
  padding-bottom: 1.6rem;
  padding-top: 1.6rem;
  text-align: left;
  text-transform: uppercase;
  width: 100%;

  &:focus {
    outline-offset: -3px;
  }

  @include mq.mq($from: m) {
    display: none;
  }
}

.navigator__panel-trigger-inner {
  padding-right: 3.6rem;
  position: relative;
}

.navigator__panel-trigger-icon {
  display: none;
  font-size: 2rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.navigator__panel-trigger-icon--open {
  [aria-expanded='false'] & {
    display: block;
  }
}

.navigator__panel-trigger-icon--close {
  [aria-expanded='true'] & {
    display: block;
  }
}

.navigator__panel-content {
  @include use-container();
  @include use-responsive-sizing(
    padding-top padding-bottom,
    (
      xs: 2.4rem,
      s: 3.2rem,
      m: 4.8rem,
      l: 6.4rem,
      xl: 8rem,
    )
  );

  &[aria-hidden='true'] {
    display: none;
  }
}

.navigator__panel-filter {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 3.2rem;
  min-height: 3.2rem;

  @include mq.mq($from: m) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.navigator__panel-filters {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  min-height: 3.2rem;

  @include mq.mq($from: m) {
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin-left: auto;
  }
}

.navigator__panel-tags {
  display: flex;
  flex-wrap: wrap;
  margin: -0.8rem;
  padding-top: 2.4rem;

  @include mq.mq($from: m) {
    order: -1;
    padding-right: 2.4rem;
    padding-top: 0;
  }
}

.navigator__panel-tag {
  display: block;
}

.navigator__panel-empty {
  align-items: center;
  border-bottom: 1px solid var(--color-offset-blue);
  border-top: 1px solid var(--color-offset-blue);
  color: color-mix(in srgb, var(--color-blue), transparent 70%);
  display: flex;
  flex-direction: column;
  font-size: 4rem;
  justify-content: center;
  min-height: 15rem;
  text-align: center;
}

.navigator__panel-loading {
  align-items: center;
  background-color: var(--color-light-blue);
  bottom: 0;
  color: var(--color-blue);
  display: flex;
  font-size: 10vw;
  justify-content: center;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: z-index('loading');

  .is-requesting & {
    opacity: 0.8;
    pointer-events: all;
  }

  @include mq.mq($from: rem2px($inner-container-max-width)) {
    font-size: 0.1 * $inner-container-max-width;
  }
}
