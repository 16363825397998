// stylelint-disable order/order
@use 'sass:map';
@use 'mq';

:root {
  // Colors
  --color-black: #{$color-black};
  --color-white: #{$color-white};
  --color-blue: #{$color-blue};
  --color-blue-2: #{$color-blue-2};
  --color-light-blue: #{$color-light-blue};
  --color-offset-blue: #{$color-offset-blue};
  --color-dark-blue: #{$color-dark-blue};
  --color-yellow: #{$color-yellow};
  --color-gray: #{$color-gray};
  --color-red: #{$color-red};
  --color-green: #{$color-green};
  --color-dark-yellow: #{$color-dark-yellow};
  --color-pink: #{$color-pink};

  // Font stacks
  --font-stack-plex: 'IBM Plex Sans', Helvetica, Arial, sans-serif;
  --font-stack-dosis: 'Dosis', Arial, Helvetica, sans-serif;

  // Font weights
  --font-weight-thin: 100;
  --font-weight-extralight: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --font-weight-black: 900;
  --font-weight-extrablack: 950;

  // Text sizes
  --font-size-default: 1.6rem;

  // Line heights
  --line-height-small: 1.2;
  --line-height-default: 1.5;
  --line-height-body: 1.8;

  // Durations
  --duration-fast: 0.1s;
  --duration-default: 0.2s;
  --duration-long: 0.3s;
  --duration-x-long: 0.4s;
  --duration-xx-long: 0.5s;

  // Root
  --root-offset: 0;
  --root-min-width: #{$root-min-width};

  // Font
  --root-font-family: var(--font-stack-plex);
  --root-color: var(--color-black);
  --root-font-width: var(--font-width-normal);
  --root-font-weight: var(--font-weight-regular);

  // Body
  --body-background-color: var(--color-white);
  --body-font-size: var(--font-size-default);

  // Focus outlines
  --focus-outline-width: 3px;
  --focus-outline-color: var(--color-black);
  --focus-outline-offset: 3px;

  // Selection
  --selection-background-color: var(--color-black);
  --selection-foreground-color: var(--color-white);

  // Breakpoints
  --breakpoint-l: #{map.get($breakpoints, l)};
  --breakpoint-m: #{map.get($breakpoints, m)};
  --breakpoint-s: #{map.get($breakpoints, s)};
  --breakpoint-xl: #{map.get($breakpoints, xl)};
  --breakpoint-xs: #{map.get($breakpoints, xs)};

  // Grid sizes
  @include use-responsive-sizing(--gap, $gaps);

  // Section padding
  @include use-responsive-sizing(--site-section-padding-inline, responsive-map(xs 4rem, xl 8rem));

  // Container and body paddings
  @include use-body-padding($properties: --inner-container-padding --body-padding);
  @include mq.mq($from: rem2px($inner-container-max-width)) {
    --body-padding: calc((100vw - #{$inner-container-max-width}) / 2 + var(--inner-container-padding));
  }
}
