@use 'mq';

.site-banner {
  align-items: center;
  background-color: var(--site-banner-background-color, var(--color-dark-blue));
  display: flex;
  gap: 2rem;
  inset-block-end: 0;
  inset-inline: 0;
  justify-content: center;
  padding-block: 1rem;
  padding-inline: 2rem;
  position: sticky;
  text-align: center;
  z-index: z-index('site-banner');
}

.site-banner__text {
  color: rgba(#fff, 0.8);
  font-family: var(--font-stack-dosis);
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2rem;

  @include mq.mq($until: m) {
    display: none;
  }
}

.site-banner__button {
  --button-font-size: 1.4rem;
  --button-padding-block: 0.5rem;
  --button-padding-inline: 1rem;
  --button-line-height: 1.6rem;
  --button-icon-size: 2rem;
}
