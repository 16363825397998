@use 'sass:map';
@use 'mq';

.u-flyout {
  background-color: var(--color-white);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top: 0.5rem solid var(--flyout-border-color, var(--color-blue));
  box-shadow: 1px 2px 2px 0 color-mix(in srgb, var(--color-black), transparent 80%);
  color: var(--color-black);
  padding: 2.4rem;
  position: absolute;
  top: calc(100% + 1.6rem);
  width: 100vw - map.get($inner-container-padding, xs) * 2;
  z-index: z-index('flyout');

  .t-white & {
    border-radius: 0.5rem;
    border-top: 0;
  }

  &[hidden] {
    display: none;
  }

  @include mq.mq($from: s) {
    width: 100vw - map.get($inner-container-padding, s) * 2;
  }

  @include mq.mq($from: m) {
    width: 100vw - map.get($inner-container-padding, m) * 2;
  }

  @include mq.mq($from: l) {
    width: 100vw - map.get($inner-container-padding, l) * 2;
  }

  @include mq.mq($from: xl) {
    width: 100vw - map.get($inner-container-padding, xl) * 2;
  }
}

.u-flyout-indicator {
  position: relative;

  &[aria-expanded='false']::after {
    display: none;
  }

  &::after {
    border-bottom: 1rem solid var(--flyout-border-color, var(--color-blue));
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    content: '';
    display: block;
    position: absolute;
    right: calc(50% - 1rem);
    top: calc(100% + 0.6rem);
  }

  .t-white &::after {
    border-bottom-color: var(--color-white);
  }
}
