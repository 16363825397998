@font-face {
  font-display: swap;
  font-family: 'Dosis';
  src: url('../../fonts/dosis-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Dosis';
  font-weight: bold;
  src: url('../../fonts/dosis-bold.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-weight: normal;
  src: url('../../fonts/ibm-plex-sans-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  src: url('../../fonts/ibm-plex-sans-italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-weight: bold;
  src: url('../../fonts/ibm-plex-sans-bold.woff2') format('woff2');
}
