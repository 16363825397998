.list-teaser {
  position: relative;
}

.list-teaser__meta {
  display: flex;
  flex-wrap: wrap;
  font-family: var(--font-stack-dosis);
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 1.6rem;
}

.list-teaser__meta-item {
  & + &::before {
    color: var(--color-gray);
    content: '|';
    margin-left: 1.2rem;
    margin-right: 1.2rem;
  }
}

.list-teaser__content {
  margin-top: 1.6rem;
}
