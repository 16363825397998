.collapse-text__summary {
  color: var(--collapse-text-summary-color, var(--color-blue-2));
  cursor: pointer;
  display: flex;
  font-weight: bold;
  line-height: 2.4rem;

  &::marker {
    display: none;
  }
}

.collapse-text__summary-icon {
  flex-shrink: 0;
}

.collapse-text__summary-icon--open {
  display: none;

  .collapse-text[open] & {
    display: block;
  }
}

.collapse-text__summary-icon--closed {
  display: block;

  .collapse-text[open] & {
    display: none;
  }
}

.collapse-text__summary-text {
  align-self: center;
  margin-left: 0.8rem;
}

.collapse-text__content {
  border-left: 2px solid var(--collapse-text-border-color, var(--color-blue-2));
  margin-left: 0.7rem;
  margin-top: 1.6rem;
  padding-left: 1.6rem;
}
