.text-button {
  align-items: center;
  display: inline-flex;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2.4rem;
  user-select: none;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.text-button__icon {
  font-size: 1.8rem;
  line-height: 0;
}

.text-button__icon--left {
  margin-right: 0.8rem;
}

.text-button__icon--right {
  margin-left: 0.8rem;
  order: 1;
}
