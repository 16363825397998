@use 'sass:map';

.filter {
  position: relative;
}

.filter__trigger {
  white-space: nowrap;
}

.filter__form {
  display: block;
  max-width: calc(100vw - #{map.get($inner-container-padding, xs) * 2});
  width: 40rem;
}

.filter__input {
  & + & {
    margin-top: 2.4rem;
  }
}

.filter__button {
  margin-top: 2.4rem;
  text-align: right;
}
