@use 'mq';

.copyright {
  background-color: rgba(0, 0, 0, 0.55);
  color: var(--color-white);
  display: inline-block;
  font-size: 0.8rem;
  line-height: 0.8rem + 2 * 0.3rem;
  max-width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: right;

  .link {
    color: var(--color-white);

    &:focus {
      outline-color: #fff;
    }
  }

  @include mq.mq($from: m) {
    font-size: 1rem;
    padding: 0.5rem 0.7rem;
  }
}
