@use 'mq';

.headline {
  color: var(--headline-color, var(--color-black));
  font-family: var(--headline-font-family, var(--font-stack-dosis));
  font-weight: bold;
  line-height: var(--line-height-small);
  text-transform: uppercase;
}

.headline--1 {
  font-size: 4.8rem;

  @include mq.mq($from: s) {
    font-size: 5.6rem;
  }

  @include mq.mq($from: m) {
    font-size: 6.1rem;
  }

  @include mq.mq($from: l) {
    font-size: 6.8rem;
  }

  @include mq.mq($from: xl) {
    font-size: 8rem;
  }
}

.headline--2 {
  font-size: 3.2rem;

  @include mq.mq($from: s) {
    font-size: 4rem;
  }

  @include mq.mq($from: m) {
    font-size: 4.3rem;
  }

  @include mq.mq($from: l) {
    font-size: 4.8rem;
  }

  @include mq.mq($from: xl) {
    font-size: 5.5rem;
  }
}

.headline--3 {
  font-size: 2.6rem;

  @include mq.mq($from: s) {
    font-size: 2.8rem;
  }

  @include mq.mq($from: m) {
    font-size: 3rem;
  }

  @include mq.mq($from: l) {
    font-size: 3.2rem;
  }

  @include mq.mq($from: xl) {
    font-size: 3.5rem;
  }
}

.headline--4 {
  font-size: 2rem;

  @include mq.mq($from: s) {
    font-size: 2.2rem;
  }

  @include mq.mq($from: l) {
    font-size: 2.4rem;
  }

  @include mq.mq($from: xl) {
    font-size: 2.6rem;
  }
}

.headline__highlight {
  color: var(--headline-highlight-color, var(--color-blue));
  font-style: normal;
}

.headline__link {
  display: block;
}

.headline__uncase {
  text-transform: none;
}
