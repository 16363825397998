@use 'mq';

.footer {
  background-color: var(--footer-background-color, var(--color-light-blue));
}

.footer__social-media {
  @include use-container();

  padding-bottom: 8rem;
  padding-top: 8rem;
  text-align: center;
}

.footer__social-media-headline {
  margin-bottom: 2.4rem;
}

.footer__social-media-links {
  display: flex;
  justify-content: center;
}

.footer__social-media-link {
  display: block;

  & + & {
    margin-left: 1.6rem;
  }
}

.footer__footer {
  background-color: var(--footer-footer-background-color, var(--color-blue));
  color: var(--footer-footer-color, var(--color-black));
  padding-bottom: 4.8rem;
  padding-top: 4.8rem;

  @include mq.mq($from: l) {
    padding-bottom: 5.6rem;
    padding-top: 5.6rem;
  }

  @include mq.mq($from: xl) {
    padding-bottom: 6.4rem;
    padding-top: 6.4rem;
  }
}

.footer__newsletter {
  @include use-container();

  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 4.8rem;
  text-align: center;

  @include mq.mq($from: s) {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }

  @include mq.mq($from: l) {
    margin-bottom: 5.6rem;
  }

  @include mq.mq($from: xl) {
    margin-bottom: 6.4rem;
  }
}

.footer__newsletter-intro {
  flex-shrink: 0;

  @include mq.mq($from: s) {
    margin-right: var(--gap);
  }
}

.footer__newsletter-headline {
  --headline-color: var(--color-white);
  --headline-highlight-color: var(--color-white);
}

.footer__newsletter-description {
  margin-top: 0.8rem;
}

.footer__newsletter-form {
  border-bottom: 2px solid var(--color-black);
  display: flex;
  margin-top: 1.6rem;
  max-width: 30rem;
  width: 100%;

  &:focus-within {
    @include use-focus-outline();
  }

  @include mq.mq($from: s) {
    margin-top: 0;
    max-width: none;
  }
}

.footer__newsletter-input {
  background-color: transparent;
  flex-grow: 1;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 0.8rem 0;

  &:focus {
    outline: 0;
  }

  &:invalid {
    box-shadow: none;
  }

  &::placeholder {
    color: var(--color-black);
    opacity: 0.5;
  }

  @include mq.mq($from: l) {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
}

.footer__newsletter-button {
  flex-shrink: 0;
  font-size: 2rem;
  line-height: 2rem;
  margin-left: 0.8rem;
  padding: 0.6rem;
  transition-property: background-color, color;

  &:hover,
  &:focus {
    background-color: var(--color-black);
    color: var(--color-blue);
    outline: 0;
  }

  @include mq.mq($from: l) {
    padding: 1.4rem;
  }
}

.footer__sitemap {
  @include use-container();

  align-items: center;
  column-gap: var(--gap);
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  text-align: center;

  @include mq.mq($from: s) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
}

.footer__navigation {
  column-gap: var(--gap);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  row-gap: 2.4rem;

  @include mq.mq($from: l) {
    flex-direction: row;
    width: column-width(8);
  }
}

.footer__navigation--compact {
  @include mq.mq($from: l) {
    flex-direction: column;
    width: column-width(3);
  }
}

.footer__navigation-item {
  display: block;
  flex-grow: 2;
  flex-shrink: 0;
}

.footer__navigation-link {
  font-family: var(--font-stack-dosis);
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
}

.footer__subnavigation {
  margin-top: 1.6rem;

  @include mq.mq($from: l) {
    margin-top: 2.4rem;
  }
}

.footer__subnavigation-item {
  display: block;

  & + & {
    margin-top: 0.8rem;

    @include mq.mq($from: l) {
      margin-top: 1.6rem;
    }
  }
}

.footer__meta-navigation {
  font-size: 1.4rem;
  margin-top: 3.2rem;

  @include mq.mq($from: s) {
    flex-shrink: 0;
    margin-top: 0;
    width: 15rem;
  }

  @include mq.mq($from: m) {
    width: 20rem;
  }
}

.footer__meta-navigation-item {
  display: block;

  & + & {
    margin-top: 1.2rem;

    @include mq.mq($from: l) {
      margin-top: 1.6rem;
    }
  }
}

.footer__logo {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  gap: 1em;
  justify-content: stretch;
  margin-top: 3.2rem;
  text-align: center;
  width: 60%;

  @include mq.mq($from: s) {
    align-items: flex-start;
    flex-shrink: 0;
    margin-left: auto;
    margin-top: 0;
    text-align: left;
    width: 20rem;
  }

  @include mq.mq($from: m) {
    width: 25rem;
  }
}
