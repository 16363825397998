.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page__header {
  position: relative;
  z-index: z-index('header');
}

.page__header--overlay {
  inset: 0 0 auto;
  position: absolute;
  z-index: z-index('overlay-header');
}

.page__breadcrumb-container {
  position: relative;
}

.page__breadcrumb {
  color: var(--color-white);
  position: relative;

  &::after {
    background-color: var(--color-blue);
    content: '';
    inset: 0;
    position: absolute;
    z-index: -1;
  }
}

.page__breadcrumb--overlay {
  left: 0;
  position: absolute;
  right: 0;
  z-index: z-index('breadcrumb');

  &::after {
    display: none;
  }
}

.page__footer {
  margin-top: auto;
}
