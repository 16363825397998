@use 'mq';

.header {
  background-color: #fff;
  position: relative;
}

.header--transparent {
  background-color: transparent;
  color: var(--color-white);
}

.header--submenu-open {
  @include mq.mq($from: l) {
    padding-bottom: 4rem;
  }

  @include mq.mq($from: xl) {
    padding-bottom: 6.2rem;
  }
}

.header__inner {
  @include use-container();

  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.header__logo {
  display: block;
  flex-shrink: 0;
  line-height: 0;
  max-width: 25rem;
  width: 55vw;

  @include mq.mq($from: m) {
    max-width: none;
    width: 25rem;
  }

  @include mq.mq($from: l) {
    width: 22rem;
  }

  @include mq.mq($from: xl) {
    width: 34rem;
  }
}

.header__navigation {
  align-items: center;
  display: flex;
}

.header__navigation-items {
  align-items: center;
  display: flex;
}

.header__navigation-item {
  display: none;
  font-family: var(--font-stack-dosis);
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;

  @include mq.mq($from: l) {
    display: block;
    font-size: 1.8rem;
  }

  @include mq.mq($from: xl) {
    font-size: 2rem;

    & + & {
      margin-left: 1.6rem;
    }
  }
}

.header__navigation-item--highlight {
  padding-left: 1.8rem;
  padding-right: 1.8rem;
}

.header__navigation-link {
  display: block;
  padding: 0.8rem;
  position: relative;

  .header__navigation-item--highlight & {
    background-color: var(--color-blue);
    border-radius: 0.5rem;
    color: var(--color-white);
    padding: 0.8rem 1.2rem;
  }

  @include mq.mq($from: m) {
    padding: 1.2rem;

    .header__navigation-item--highlight & {
      padding: 1.2rem 1.6rem;
    }
  }
}

.header__navigation-link-indicator {
  bottom: 0;
  color: var(--color-black);
  font-size: 1.4rem;
  left: 50%;
  line-height: 0;
  position: absolute;
  transform: translate(-50%, 125%);

  .header__navigation-item--highlight & {
    color: var(--color-blue);
  }

  @include mq.mq($from: xl) {
    transform: translate(-50%, 150%);
  }
}

.header__subnavigation-items {
  border-top: 1px solid color-mix(in srgb, var(--color-black), transparent 70%);
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;

  @include mq.mq($from: l) {
    display: flex;
  }
}

.header__subnavigation-item {
  display: flex;
}

.header__subnavigation-link {
  display: block;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 1rem 1.6rem;

  @include mq.mq($from: xl) {
    font-size: 1.6rem;
    padding: 2.1rem 2.8rem;
  }
}

.header__subnavigation-link--active {
  background-color: var(--color-light-blue);
  color: var(--color-black);
}

.header__newsletter {
  flex-shrink: 0;

  @include mq.mq($from: l) {
    border-left: 1px solid color-mix(in srgb, var(--color-black), transparent 70%);
    margin-left: 1.2rem;
    padding-left: 1.2rem;

    .header--transparent & {
      border-left-color: color-mix(in srgb, var(--color-white), transparent 70%);
    }
  }

  @include mq.mq($from: xl) {
    margin-left: 3.2rem !important;
    padding-left: 3.2rem;
  }
}

.header__menu,
.header__search {
  margin-left: 0.4rem;
  position: relative;

  @include mq.mq($from: xl) {
    margin-left: 1.6rem;
  }
}

.header__search-form {
  max-width: 40rem;
  right: 0;
}

.header__search-form-button {
  margin-top: 1.6rem;
  text-align: right;
}

.header__menu-flyout {
  max-width: 30rem;
  right: 0;
}

.header__menu-flyout-items {
  font-family: var(--font-stack-dosis);
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
}

.header__menu-flyout-items--primary {
  @include mq.mq($from: l) {
    display: none;
  }
}

.header__menu-flyout-items--secondary {
  @include mq.mq($until: l) {
    border-top: 1px solid color-mix(in srgb, var(--color-black), transparent 70%);
    margin-top: 2.4rem;
    padding-top: 2.4rem;
  }
}

.header__menu-flyout-items--sub {
  font-size: 1.6rem;
  margin-bottom: 0.8rem;
  margin-top: 0.4rem;
  padding-left: 1.6rem;
}

.header__menu-flyout-item {
  display: block;
}

.header__menu-flyout-item--line {
  border-top: 1px solid color-mix(in srgb, var(--color-black), transparent 70%);
  margin-top: 2.4rem;
  padding-top: 2.4rem;
}

.header__menu-flyout-link {
  display: block;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
  position: relative;
}

.header__menu-flyout-link--highlight {
  background-color: var(--color-blue);
  color: var(--color-white);
  margin-left: -2.4rem;
  margin-right: -2.4rem;
  margin-top: 0.4rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.header__menu-flyout-link-icon {
  font-size: 0.9em;
  margin-inline-end: 0.5em;
  vertical-align: -0.15em;
}

.header__menu-flyout-link-indicator {
  font-size: 1.4rem;
  left: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  transform: translate(calc(-100% - 0.4rem), -50%);

  .header__menu-flyout-link--highlight & {
    left: 2.4rem;
  }
}

.header__button {
  border-radius: 50%;
  display: block;
  font-size: 2rem;
  line-height: 0;
  padding: 0.8rem;

  &[aria-expanded='true'],
  &:hover,
  &:not(.u-invisible-focus):focus {
    background-color: var(--color-blue);
    color: var(--color-white);
  }

  @include mq.mq($from: m) {
    padding: 1.2rem;
  }
}

.header--transparent .header__button {
  &[aria-expanded='true'],
  &:hover,
  &:not(.u-invisible-focus):focus {
    background-color: var(--color-white);
    color: var(--color-black);
  }
}
