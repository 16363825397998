.preview {
  min-height: 100vh;
  padding: 2rem;
}

.preview--paddingless {
  padding: 0;
}

.preview--light-blue {
  background-color: var(--color-light-blue);
}

.preview--blue {
  background-color: var(--color-blue);
}
