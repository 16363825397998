@use 'mq';

.content {
  @include use-responsive-sizing(padding-top padding-bottom, $content-container-paddings);
}

.content--transparent {
  .u-content-container + & {
    padding-top: 0;
  }
}

.content--blue {
  background-color: var(--color-light-blue);
}

.content--yellow {
  --link-color: var(--color-black);

  background-color: var(--color-yellow);
}

.content--intro {
  font-weight: bold;

  @include mq.mq($from: m) {
    font-size: 2rem;
  }
}

.content__inner {
  @include use-container();
}

.content__headline {
  margin-bottom: 4.8rem;
  text-align: center;
}

.content__content {
  @include use-responsive-sizing(
    width,
    (
      m: column-width(10),
      l: column-width(8),
      xl: column-width(8),
    )
  );

  display: flow-root;
  margin-left: auto;
  margin-right: auto;

  > aside,
  > details,
  > nav,
  > .form {
    margin-bottom: 3.2rem;
    margin-top: 3.2rem;
  }

  > .infobox,
  > figure,
  > .gallery {
    margin-bottom: 4rem;
    margin-top: 4rem;

    @include mq.mq($from: m) {
      margin-bottom: 4.8rem;
      margin-top: 4.8rem;
    }
  }

  > .infobox,
  > .gallery,
  > .form,
  > details,
  > nav {
    clear: both;
  }

  > .u-float-left,
  > .u-float-right,
  > .u-float-left + *,
  > .u-float-right + * {
    margin-top: 0;
  }
}

.content--wide .content__content {
  @include use-responsive-sizing(
    width,
    (
      m: column-width(12),
      l: column-width(10),
      xl: column-width(10),
    )
  );
}

.content--wider .content__content {
  @include use-responsive-sizing(
    width,
    (
      m: column-width(12),
      l: column-width(12),
      xl: column-width(12),
    )
  );
}
