@use 'sass:list';
@use 'mq';

.case-teaser-grid {
  @include use-responsive-sizing(
    padding-top padding-bottom,
    (
      xs: 4.8rem,
      s: 7.2rem,
      m: 8.8rem,
      l: 10.4rem,
      xl: 14.4rem,
    )
  );

  background-color: var(--color-light-blue);
  position: relative;
}

.case-teaser-grid__inner {
  @include use-container();
}

.case-teaser-grid__headline {
  margin-bottom: 4.8rem;
  text-align: center;
}

.case-teaser-grid__filter {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 3.2rem;
  min-height: 3.2rem;

  @include mq.mq($from: m) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.case-teaser-grid__filters {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  min-height: 3.2rem;

  @include mq.mq($from: m) {
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin-left: auto;
  }
}

.case-teaser-grid__tags {
  display: flex;
  flex-wrap: wrap;
  margin: -0.8rem;
  padding-top: 2.4rem;

  @include mq.mq($from: m) {
    order: -1;
    padding-right: 2.4rem;
    padding-top: 0;
  }
}

.case-teaser-grid__tag {
  display: block;
}

.case-teaser-grid__case-teasers {
  @include use-responsive-sizing(margin, map-multiply($gaps, -0.5));

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.case-teaser-grid__case-teaser {
  @include use-responsive-sizing(padding, map-multiply($gaps, 0.5));
  @include use-responsive-sizing(
    --case-teaser-aspect-ratio,
    (
      xs: list.slash(4, 3),
      m: list.slash(1, 1),
      l: list.slash(700, 595),
    )
  );

  align-items: stretch;
  display: flex;
  flex-shrink: 0;
  width: 100%;

  .case-teaser-grid--2 & {
    @include mq.mq($from: m) {
      width: 50%;
    }
  }

  .case-teaser-grid--3 & {
    @include mq.mq($from: l) {
      width: 33.33%;
    }
  }
}

.case-teaser-grid__load-more-button {
  @include use-responsive-sizing(padding, map-multiply($gaps, 0.5));

  flex-grow: 1;
  flex-shrink: 0;
  text-align: center;
  width: 100%;
}

.case-teaser-grid__more-link {
  margin-top: 3.2rem;
  text-align: right;
}

.case-teaser-grid__loading {
  align-items: center;
  background-color: var(--color-light-blue);
  bottom: 0;
  color: var(--color-blue);
  display: flex;
  font-size: 10vw;
  justify-content: center;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: z-index('loading');

  .is-requesting & {
    opacity: 0.8;
    pointer-events: all;
  }

  @include mq.mq($from: rem2px($inner-container-max-width)) {
    font-size: 0.1 * $inner-container-max-width;
  }
}
