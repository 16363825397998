@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }

  50% {
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
  }
}

@keyframes js-trigger {
  100% {
    opacity: 1;
  }
}
