.field-group-repeater__label {
  color: var(--color-black);
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 1.6rem;
  width: 100%;
}

.field-group-repeater__field-group-row {
  border: 1px solid var(--color-black);
  margin-bottom: 2.4rem;
  padding: 2.4rem;
  position: relative;
}

.field-group-repeater__field-group-button {
  background-color: var(--color-black);
  border: 1px solid var(--color-black);
  border-radius: 50%;
  color: var(--color-white);
  height: 3.2rem;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  transition-property: background-color, color;
  width: 3.2rem;

  &:hover,
  &:focus {
    background-color: var(--color-white);
    color: var(--color-black);
  }
}

.field-group-repeater__description {
  display: flex;
  font-size: 1.6rem;
  line-height: var(--line-height-default);
  margin-top: 1.2rem;
}

.field-group-repeater__description-icon {
  flex-shrink: 0;
  margin-right: 0.8rem;
  margin-top: 0.5rem;
}

.field-group-repeater__add {
  text-align: right;
}
