@use 'mq';

$person-teaser-grid-gaps: (
  xs: 3.6rem,
  s: 5.2rem,
  m: 6rem,
  l: 7.2rem,
  xl: 9.6rem,
);

.person-teaser-grid {
  @include use-responsive-sizing(padding-top padding-bottom, $content-container-paddings);

  .u-content-container + & {
    padding-top: 0;
  }
}

.person-teaser-grid__inner {
  @include use-container();
}

.person-teaser-grid__person-teasers {
  @include use-responsive-sizing(margin-top margin-bottom, map-multiply($person-teaser-grid-gaps, -0.5));
  @include use-responsive-sizing(margin-left margin-right, map-multiply($gaps, -0.5));

  display: flex;
  flex-wrap: wrap;
}

.person-teaser-grid__person-teaser {
  @include use-responsive-sizing(padding-top padding-bottom, map-multiply($person-teaser-grid-gaps, 0.5));
  @include use-responsive-sizing(padding-left padding-right, map-multiply($gaps, 0.5));

  width: 100%;

  @include mq.mq($from: m) {
    .person-teaser-grid--2 & {
      width: 50%;
    }
  }
}
