:root {
  --image-max-width: none;
  --image-max-height: none;
  --image-z-index: #{z-index('default')};
  --image-zoom: 1;
}

.image {
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  flex-grow: 1;
  line-height: 0;
  max-height: var(--image-max-height);
  max-width: var(--image-max-width);
  position: relative;
  z-index: var(--image-z-index);
}

.image--cover {
  overflow: hidden;
}

.image__img {
  display: block;
  font-size: 1.4rem;
  height: auto;
  position: relative;
  transition-duration: var(--duration-long);
  transition-property: opacity;
  width: 100%;
  z-index: 2;

  .js & {
    opacity: 0;
  }

  .js .image--loaded & {
    opacity: 1;
  }

  .image--cover & {
    height: 100%;
    inset: 0;
    object-fit: cover;
    position: absolute;
    scale: var(--image-zoom);
    transition-property: scale;
    width: 100%;
  }
}
