@use 'mq';

.case-header {
  position: relative;
}

.case-header__image {
  @include use-responsive-sizing(
    height,
    (
      xs: 45rem,
      s: 50.5rem,
      m: 54rem,
      l: 59rem,
      xl: 67rem,
    )
  );
  --image-z-index: 1;

  background-color: var(--color-blue);
  display: flex;
  flex-direction: column;
  position: relative;

  &::after {
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    inset: 0;
    position: absolute;
    z-index: 2;
  }
}

.case-header__image-copyright {
  @include use-responsive-sizing(right, $inner-container-padding, xs, m, true);
  @include use-responsive-sizing(
    bottom,
    (
      xs: 25rem,
      l: 0,
    )
  );

  position: absolute;
  right: 0;
  z-index: 3;
}

.case-header__case-header {
  @include mq.mq($from: l) {
    inset: 0;
    position: absolute;
  }
}

.case-header__inner {
  @include use-container();

  margin-top: -25rem;
  pointer-events: none;
  position: relative;
  z-index: 2;

  @include mq.mq($from: l) {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    margin-top: 0;
  }
}

.case-header__inner-box {
  @include use-responsive-sizing(padding, $gaps);
  @include use-responsive-sizing(
    width,
    (
      l: column-width(10),
    )
  );

  background-color: var(--color-white);
  margin-left: auto;
  margin-right: auto;
  pointer-events: all;
}

.case-header__meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.2rem;

  @include mq.mq($from: l) {
    margin-bottom: 4rem;
  }
}

.case-header__kicker {
  font-family: var(--font-stack-dosis);
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.case-header__law-articles {
  margin-left: auto;
}

.case-header__content {
  @include use-responsive-sizing(margin, map-multiply($gaps, -0.5), l);

  @include mq.mq($from: l) {
    display: flex;
  }
}

.case-header__headline,
.case-header__additional {
  @include use-responsive-sizing(padding, map-multiply($gaps, 0.5), l);

  @include mq.mq($from: l) {
    flex-shrink: 0;
    width: 50%;
  }
}

.case-header__headline {
  @include use-responsive-sizing(font-size, responsive-map(xs 3rem, xl 4.5rem));

  color: var(--color-black);
  font-family: var(--font-stack-dosis);
  font-weight: bold;
  line-height: var(--line-height-small);
  text-transform: uppercase;
}

.case-header__highlight {
  color: var(--color-blue);
  font-style: normal;
}

.case-header__uncase {
  text-transform: none;
}

.case-header__additional {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  margin-top: 2.4rem;

  @include mq.mq($from: l) {
    margin-top: 0;
  }
}

.case-header__description {
  @include use-responsive-sizing(
    font-size,
    (
      xs: 2rem,
      m: 2.2rem,
    )
  );

  font-family: var(--font-stack-dosis);
  font-size: 2rem;
  line-height: var(--line-height-small);
}

.case-header__box {
  margin-top: auto;
}
