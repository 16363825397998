@use 'mq';

.infobox {
  background-color: var(--color-light-blue);
  border: 5px solid var(--color-offset-blue);
  display: flex;
  flex-direction: column;
  gap: 2rem 4rem;
  padding: 2rem;

  @include mq.mq($from: m) {
    flex-direction: row;
    padding: 4rem;
  }
}

.infobox--right {
  @include mq.mq($from: m) {
    flex-direction: row-reverse;
  }
}

.infobox__figure {
  @include mq.mq($from: m) {
    flex-shrink: 0;
    width: 33.33%;
  }
}
