.law-articles {
  display: flex;
  font-family: var(--font-stack-dosis);
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.law-articles__label {
  margin-right: 0.5rem;
}

.law-articles__article {
  border-radius: 1.2rem;
  box-shadow: inset 0 0 0 1px currentColor;
  height: 2.4rem;
  min-width: 2.4rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  text-align: center;

  & + & {
    margin-left: 0.4rem;
  }
}
