.satellite-header {
  background-color: var(--satellite-header-background-color, var(--color-white));
  position: relative;
}

.satellite-header__inner {
  @include use-container();

  align-items: center;
  column-gap: 2.8rem;
  container-name: satellite-header;
  container-type: inline-size;
  display: flex;
  justify-content: space-between;
  min-block-size: clamp(4rem, 10cqi, 10rem);
  padding-block: var(--satellite-header-padding-block, 2rem);
}

.satellite-header__logo {
  flex-shrink: 0;
  margin-inline-end: auto;
}

.satellite-header__logo-link {
  display: block;
  line-height: 0;
}

.satellite-header__logo-img {
  height: 8rem;
  width: auto;
}

.satellite-header__logo-text {
  background-color: var(--satellite-header-logo-background-color, var(--color-black));
  border-radius: 0.5rem;
  color: var(--satellite-header-logo-color, var(--color-white));
  font-family: var(--satellite-header-logo-font-family, var(--font-stack-dosis));
  font-size: var(--satellite-header-logo-font-size, clamp(1.4rem, 1.5cqi, 2.4rem));
  font-weight: var(--satellite-header-logo-font-weight, bold);
  padding-block: 0.3em;
  padding-inline: 0.8em;
  text-transform: var(--satellite-header-logo-text-transform, uppercase);
}

.satellite-header__items {
  --_satellite-header-items-column-gap: var(--satellite-header-items-column-gap, calc(5rem + 0.4cqi));
  --_satellite-header-items-divider-block-size: var(--satellite-header-items-divider-block-size, 1px);

  align-items: stretch;
  column-gap: calc(var(--_satellite-header-items-column-gap) + var(--_satellite-header-items-divider-block-size));
  display: flex;
  font-family: var(--satellite-header-items-font-family, var(--font-stack-dosis));
  font-size: var(--satellite-header-items-font-size, calc(1.8rem + 0.2cqi));
  font-weight: var(--satellite-header-items-font-weight, bold);
  justify-content: flex-end;
  text-transform: var(--satellite-header-items-text-transform, uppercase);

  > * {
    position: relative;
  }

  > *:not(:last-child)::after {
    background-color: color-mix(in srgb, var(--color-black) 20%, var(--color-white));
    content: '';
    display: block;
    inset-block: -0.2rem;
    inset-inline-end: calc(var(--_satellite-header-items-column-gap) / -2);
    position: absolute;
    width: var(--_satellite-header-items-divider-block-size);
  }

  @container satellite-header (inline-size < 70rem) {
    column-gap: calc(
      var(--_satellite-header-items-column-gap) / 1.5 + var(--_satellite-header-items-divider-block-size)
    );

    > *:not(:last-child)::after {
      inset-block: 0;
      inset-inline-end: calc(var(--_satellite-header-items-column-gap) / 1.5 / -2);
    }
  }
}

.satellite-header__button {
  background-color: var(--satellite-header-button-background-color, transparent);
  border-radius: 50%;
  color: var(--satellite-header-button-color, var(--color-black));
  font-size: var(--satellite-header-button-font-size, 2rem);
  height: 2em;
  line-height: 0;
  margin-inline: -0.5em;
  padding: 0.5em;
  transition-property: background-color, color;
  width: 2em;

  &:is(:hover, :focus-visible:not(.u-invisible-focus)) {
    background-color: var(--satellite-header-button-background-color--enter, var(--color-black));
    color: var(--satellite-header-button-color--enter, var(--color-white));
  }
}

.satellite-header__menu {
  @container satellite-header (inline-size >= 70rem) {
    display: none;
  }
}

.satellite-header__navigation {
  align-items: center;
  display: flex;

  @container satellite-header (inline-size < 70rem) {
    align-items: flex-start;
    background-color: color-mix(in srgb, var(--color-black) 2%, var(--color-white));
    height: calc(100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0));
    inset: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 4rem;
    position: fixed;
    z-index: 5;

    &:not(.satellite-header__navigation--open) {
      display: none;
    }

    @supports (-webkit-touch-callout: none) {
      /* stylelint-disable-next-line value-no-vendor-prefix */
      height: -webkit-fill-available;
    }
  }

  @container satellite-header (inline-size >= 70rem) {
    animation: js-trigger 0s;
  }
}

.satellite-header__navigation-items {
  align-items: center;
  column-gap: var(--satellite-header-navigation-column-gap, 1em);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  row-gap: var(--satellite-header-navigation-column-gap, 0.4em);

  @container satellite-header (inline-size < 70rem) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: calc(var(--satellite-header-navigation-column-gap, 0.4em) * 2);
  }
}

.satellite-header__navigation-item {
  display: block;
  position: relative;
  white-space: nowrap;
}

.satellite-header__navigation-link {
  text-transform: inherit;
  user-select: none;
}

.satellite-header__navigation-submenu {
  display: flex;
  flex-direction: column;
  margin-block-end: var(--satellite-header-navigation-column-gap, 0.4em);
  margin-block-start: calc(var(--satellite-header-navigation-column-gap, 0.4em) * 2);
  margin-inline-start: 1em;
  row-gap: calc(var(--satellite-header-navigation-column-gap, 0.4em) * 2);

  @container satellite-header (inline-size >= 70rem) {
    background-color: color-mix(in srgb, var(--color-black) 2%, var(--color-white));
    border-end-end-radius: 0.5rem;
    border-end-start-radius: 0.5rem;
    border-top: 0.5rem solid var(--color-blue);
    box-shadow: 1px 2px 2px 0 color-mix(in srgb, var(--color-black), transparent 80%);
    inset-block-start: calc(100% + 2.5rem);
    inset-inline-start: 50%;
    margin-block-start: 0;
    margin-inline-start: 0;
    padding-block: 2rem;
    position: absolute;
    row-gap: var(--satellite-header-navigation-column-gap, 0.4em);
    translate: -50% 0;
    width: 30rem;
    z-index: 5;

    &:is([hidden]) {
      display: none;
    }

    &::before {
      border-bottom: 1rem solid var(--color-blue);
      border-left: 1rem solid transparent;
      border-right: 1rem solid transparent;
      content: '';
      display: block;
      inset-block-start: 0;
      inset-inline-start: 50%;
      position: absolute;
      translate: -50% -150%;
    }
  }
}

.satellite-header__navigation-submenu-item {
  display: block;

  @container satellite-header (inline-size >= 70rem) {
    text-align: center;
  }
}

.satellite-header__navigation-submenu-link {
  display: block;

  @container satellite-header (inline-size >= 70rem) {
    padding-block: 0.8rem;
    padding-inline: 2rem;
  }
}

.satellite-header__close {
  inset-block-start: 3rem;
  inset-inline-end: 3rem;
  position: absolute;

  @container satellite-header (inline-size >= 70rem) {
    display: none;
  }
}

.satellite-header__search {
  align-items: center;
  display: flex;
  position: relative;
}

.satellite-header__search-form {
  max-width: 40rem;
  right: 0;
}

.satellite-header__search-form-button {
  margin-top: 1.6rem;
  text-align: right;
}

.satellite-header__languages {
  align-items: center;
  column-gap: var(--satellite-header-languages-column-gap, 1em);
  display: flex;
  row-gap: var(--satellite-header-languages-column-gap, 0.4em);
}

.satellite-header__language {
  display: block;
}
