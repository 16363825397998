@use 'mq';

.list-teasers__inner {
  @include use-container();
  @include use-responsive-sizing(
    padding-top padding-bottom,
    (
      xs: 4.8rem,
      s: 5.2rem,
      m: 5.6rem,
      l: 6.4rem,
      xl: 8rem,
    )
  );
}

.list-teasers__headline {
  margin-bottom: 4.8rem;
}

.list-teasers__search {
  @include use-break-out();

  background-color: var(--color-light-blue);
  margin-bottom: 4.8rem;
}

.list-teasers__search-inner {
  @include use-container();

  padding-bottom: 3.2rem;
  padding-top: 3.2rem;
}

.list-teasers__search-input-button {
  display: flex;
}

.list-teasers__search-input {
  flex-grow: 1;
  margin-right: 1.6rem;
}

.list-teasers__search-button {
  flex-shrink: 0;
}

.list-teasers__search-filters {
  align-items: flex-start;
  margin-top: 3.2rem;

  @include mq.mq($from: m) {
    display: flex;
  }
}

.list-teasers__search-filter {
  display: inline-block;
  flex-shrink: 0;
  margin-left: auto;
  position: relative;

  & + & {
    margin-left: 3.2rem;
  }
}

.list-teasers__search-filter-flyout {
  display: block;
  left: 0;
  max-width: 40rem;

  @include mq.mq($from: m) {
    left: auto;
    right: 0;
  }
}

.list-teasers__search-filter-field {
  & + & {
    margin-top: 2.4rem;
  }
}

.list-teasers__search-filter-button {
  margin-top: 2.4rem;
  text-align: right;
}

.list-teasers__list-teaser {
  border-bottom: 1px solid var(--color-offset-blue);
  border-top: 1px solid var(--color-offset-blue);
  display: block;
  padding-bottom: 3.2rem;
  padding-top: 3.2rem;
  position: relative;

  &:not(:first-child) {
    margin-top: -1px;
  }
}

.list-teasers__pagination {
  @include use-responsive-sizing(
    margin-top,
    (
      xs: 3.2rem,
      s: 4rem,
      m: 4.8rem,
      l: 5.6rem,
      xl: 6.4rem,
    )
  );
}
