.field {
  line-height: 1;
}

.field__box {
  background-color: var(--color-white);
  border-radius: 0.5rem;
  box-shadow:
    0 0 0 0.3rem transparent,
    inset 0 0 0 0.1rem var(--color-gray);
  padding: 1.2rem 2rem;
  position: relative;
  transition-property: box-shadow;

  &:hover {
    box-shadow:
      0 0 0 0.3rem transparent,
      inset 0 0 0 0.1rem var(--color-black);
  }

  &:focus-within {
    box-shadow:
      0 0 0 0.3rem var(--color-black),
      inset 0 0 0 0.1rem var(--color-black);
  }
}

.field__box--has-error {
  box-shadow:
    0 0 0 0.3rem transparent,
    inset 0 0 0 0.1rem var(--color-red);
}

.field__box-label {
  display: block;
  font-size: 1.4rem;
  margin-bottom: 0.8rem;

  &::before {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}

.field__box-input {
  background-color: var(--color-white);
  display: block;
  font-family: var(--font-stack-dosis);
  font-size: 2.6rem;
  font-weight: bold;
  height: 3.2rem;
  line-height: 3rem;
  max-width: 100%;
  min-width: 100%;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 2;

  &:focus {
    outline: 0;
  }

  &[rows],
  &[multiple] {
    height: auto;
  }

  &[aria-invalid='true'] {
    color: var(--color-red);
  }

  &:invalid {
    box-shadow: none;
  }
}

.field__box-input--color {
  height: 3.2rem;
}

.field__box-input--small {
  font-size: 2rem;
  height: 2.6rem;
  line-height: 2.6rem;
}

.field__box-input--select,
.field__box-input--color {
  cursor: pointer;
}

.field__box-input--hidden {
  display: none;
}

.field__box-input--file {
  font-size: 2rem;
}

.field__box-input--has-icon {
  padding-right: 2.4rem;
}

.field__box-option {
  &[disabled] {
    color: var(--color-gray);
    cursor: not-allowed;
  }
}

.field__box-icon {
  bottom: 1.6rem;
  font-size: 1.8rem;
  height: 1.8rem;
  line-height: 0;
  pointer-events: none;
  position: absolute;
  right: 1.6rem;
  width: 1.8rem;
  z-index: 3;
}

.field__limit {
  background-color: var(--color-black);
  border-radius: 1.2rem;
  color: var(--color-white);
  display: inline-block;
  font-size: 1rem;
  line-height: 1;
  margin-top: 1.2rem;
  opacity: 0.3;
  padding: 0.4rem 1rem;
  transition-property: opacity;

  .field:focus-within & {
    opacity: 1;
  }
}

.field__legend {
  color: var(--color-black);
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 1.6rem;
}

.field__line {
  cursor: pointer;
  display: flex;
  user-select: none;

  & + & {
    margin-top: 0.8rem;
  }

  &:focus-within {
    @include use-focus-outline();
  }
}

.field__checkbox,
.field__radio {
  flex-shrink: 0;
  position: relative;
}

.field__checkbox-input,
.field__radio-input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: -1;

  &:focus {
    outline: 0;
  }
}

.field__checkbox-icon {
  border-radius: 0.3rem;
  box-shadow: inset 0 0 0 0.2rem var(--color-black);
  color: transparent;
  cursor: pointer;
  font-size: 1.6rem;
  height: 2.4rem;
  line-height: 0;
  padding: 0.4rem;
  transition-property: background-color, color;
  user-select: none;
  width: 2.4rem;

  .field__checkbox-input:checked ~ & {
    background-color: var(--color-black);
    color: var(--color-white);
  }
}

.field__radio-icon {
  border-radius: 50%;
  box-shadow: inset 0 0 0 0.2rem var(--color-black);
  cursor: pointer;
  font-size: 1.4rem;
  height: 2.4rem;
  line-height: 0;
  padding: 0.5rem;
  position: relative;
  user-select: none;
  width: 2.4rem;

  &::after {
    background-color: currentColor;
    border-radius: 50%;
    content: '';
    height: 1.4rem;
    left: 0.5rem;
    opacity: 0;
    position: absolute;
    top: 0.5rem;
    transition-property: opacity;
    width: 1.4rem;
  }

  .field__radio-input:checked ~ &::after {
    opacity: 1;
  }
}

.field__line-label {
  flex-grow: 2;
  font-size: 1.6rem;
  line-height: var(--line-height-default);
  padding-left: 1.2rem;
}

.field__extra-content {
  margin-top: 1rem;
}

.field__error,
.field__description {
  display: flex;
  font-size: 1.6rem;
  line-height: var(--line-height-default);
  margin-top: 1.2rem;
}

.field__error {
  color: var(--color-red);
  font-weight: bold;
}

.field__description-icon,
.field__error-icon {
  flex-shrink: 0;
  margin-right: 0.8rem;
  margin-top: 0.5rem;
}
