.icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  line-height: 0;
  user-select: all;
  vertical-align: middle;
  width: 1em;
}

.icon--slider-left,
.icon--slider-right {
  width: 0.4em;
}

.icon--reload {
  animation: spin 1.2s infinite paused;

  .is-requesting & {
    animation-play-state: running;
  }
}
