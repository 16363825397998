@use 'mq';

.person-teaser {
  align-items: center;
  display: flex;
  flex-direction: column;

  @include mq.mq($from: m) {
    align-items: center;
    flex-direction: row;
  }
}

.person-teaser__image {
  @include use-responsive-sizing(margin-bottom, $gaps, xs, s, true);
  @include use-responsive-sizing(margin-right, $gaps, m);

  border-radius: 50%;
  flex-shrink: 0;
  max-width: 27rem;
  overflow: hidden;
  width: 50%;

  @include mq.mq($from: m) {
    width: 33.33%;
  }
}

.person-teaser__content {
  text-align: center;

  @include mq.mq($from: m) {
    text-align: left;
  }
}

.person-teaser__name {
  color: var(--person-teaser-name-color, var(--color-blue));
  font-family: var(--font-stack-dosis);
  font-size: 2.6rem;
  font-weight: bold;
  line-height: var(--line-height-small);
}

.person-teaser__title {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: var(--line-height-default);
  margin-top: 0.8rem;
}

.person-teaser__description {
  font-size: 1.6rem;
  line-height: var(--line-height-default);
  margin-top: 1.2rem;
}

.person-teaser__quote {
  font-family: var(--font-stack-dosis);
  font-size: 2.6rem;
  font-weight: bold;
  line-height: var(--line-height-small);
  margin-top: 1.6rem;
}

.person-teaser__more-link {
  margin-top: 1.6rem;
}
