@use 'sass:map';
@use 'sass:math';
@use 'mq';

.u-hidden-visually {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.u-skip-link {
  background-color: var(--color-blue);
  color: var(--color-white);
  font-size: 2rem;
  font-weight: bold;
  left: 0;
  outline: 0;
  padding: 1.6rem 3.2rem;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  transform: translateY(-500%);
  z-index: z-index('skip-link');

  &:hover,
  &:focus,
  &:active {
    transform: translateY(0);
  }
}

.u-overlay-link {
  &::after {
    content: '';
    inset: var(--overlay-link-inset, 0);
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    z-index: var(--overlay-link-z-index, 2);
  }
}

.u-underline-focus {
  box-shadow: inset 0 -1px 0 0 transparent;
  position: relative;
  transition-property: box-shadow;

  button:not([disabled]):hover &,
  button:not(.u-invisible-focus):focus &,
  :any-link:hover &,
  :any-link:not(.u-invisible-focus):focus &,
  .has-underline:hover &,
  .has-underline:not(.u-invisible-focus):focus &,
  .has-underline:not(.u-invisible-focus):focus-within & {
    box-shadow: inset 0 -1px 0 0 currentColor;
  }
}

.u-underline {
  box-shadow: inset 0 -1px 0 0 currentColor;
  position: relative;
  transition-property: box-shadow;

  button:not([disabled]):hover &,
  button:not(.u-invisible-focus):focus &,
  :any-link:hover &,
  :any-link:not(.u-invisible-focus):focus &,
  .has-underline:hover &,
  .has-underline:not(.u-invisible-focus):focus &,
  .has-underline:not(.u-invisible-focus):focus-within & {
    box-shadow: inset 0 -1px 0 0 transparent;
  }
}

.u-align-center {
  text-align: center;
}

.u-align-right {
  text-align: right;
}

.u-float-left {
  @each $breakpoint in (s, m, l, xl) {
    @include mq.mq($from: $breakpoint) {
      $gap: map.get($gaps, $breakpoint);

      float: left;
      margin-right: $gap;
      width: calc(50% - #{math.div($gap, 2)});
    }
  }
}

.u-float-right {
  @each $breakpoint in (s, m, l, xl) {
    @include mq.mq($from: $breakpoint) {
      $gap: map.get($gaps, $breakpoint);

      float: right;
      margin-left: $gap;
      width: calc(50% - #{math.div($gap, 2)});
    }
  }
}

.u-format-text {
  font-size: 1.6rem;
  line-height: var(--line-height-default);

  > * + * {
    margin-top: 1.6rem;
  }

  > * + .headline {
    margin-top: 3.2rem;

    @include mq.mq($from: m) {
      margin-top: 4rem;
    }
  }

  > ul,
  > ol,
  > ul ul,
  > ol ol {
    padding-left: 3.2rem;
  }

  > ul,
  > ul ul {
    list-style-type: square;
  }

  @include mq.mq($from: m) {
    font-size: 1.8rem;
    line-height: var(--line-height-body);
  }
}

.u-container {
  @include use-container();
}
