.progress {
  position: relative;
}

.progress__label {
  font-size: 1.6rem;
  line-height: var(--line-height-default);
  margin-bottom: 0.8rem;
}

.progress__progress {
  background-color: var(--color-black);
  border-radius: 0.4rem;
  height: 0.8rem;

  &::before {
    background-color: color-mix(in srgb, var(--color-black), transparent 90%);
    border-radius: 0.4rem;
    content: '';
    height: 0.8rem;
    left: 0;
    position: absolute;
    right: 0;
  }
}
