.t-center-for-user-rights {
  --button-background-color: var(--color-pink);
  --button-secondary-background-color: var(--color-dark-blue);
  --button-secondary-color: var(--color-white);
  --headline-highlight-color: var(--color-pink);
  --link-color: var(--color-pink);
  --collapse-text-summary-color: var(--color-pink);
  --collapse-text-border-color: var(--color-pink);
  --footer-footer-background-color: var(--color-dark-blue);
  --footer-footer-color: var(--color-white);
  --site-banner-background-color: var(--color-pink);
  --flyout-border-color: var(--color-pink);
  --person-teaser-name-color: var(--color-pink);
  --page-header-image-background-color: var(--color-pink);
}
