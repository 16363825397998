@use 'mq';

.page-header {
  background-color: var(--color-light-blue);
  position: relative;
}

.page-header__image {
  background-color: var(--page-header-image-background-color, var(--color-blue-2));
  display: flex;
  flex-direction: column;
  inset: 0;
  position: absolute;
  z-index: 1;

  @include mq.mq($from: l) {
    max-height: none;
  }
}

.page-header--with-image .page-header__image {
  height: 40rem;
  inset: 0 0 auto;
  max-height: none;

  &::after {
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    inset: 0;
    position: absolute;
    z-index: 2;
  }

  @include mq.mq($from: l) {
    height: 100%;
    inset: 0;
  }
}

.page-header__inner {
  @include use-container();

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .page-header--with-image & {
    min-height: 40rem;

    @include mq.mq($from: l) {
      min-height: 60rem;
    }

    @include mq.mq($from: xl) {
      min-height: 67rem;
    }
  }

  @each $breakpoint, $padding in $inner-container-padding {
    @include mq.mq($from: $breakpoint) {
      padding-bottom: $padding;
      padding-top: calc(#{$padding} + 4.5rem);
      @if $breakpoint == 'xs' {
        $padding: $padding * 2;
      }
    }
  }

  @include mq.mq($from: l) {
    flex-direction: row;
  }
}

.page-header__content,
.page-header__copyright,
.page-header__box {
  position: relative;
  z-index: 2;
}

.page-header__content {
  align-items: center;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @include mq.mq($from: l) {
    flex-shrink: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
    width: column-width(6);
  }
}

.page-header--article:not(.page-header--with-image) .page-header__content {
  @include mq.mq($from: l) {
    width: column-width(10);
  }
}

.page-header__headline {
  --headline-color: var(--color-white);
  --headline-highlight-color: var(--color-white);
}

.page-header__date {
  @include use-responsive-sizing(
    font-size,
    (
      xs: 1.6rem,
      l: 1.8rem,
      xl: 2rem,
    )
  );

  margin-bottom: 1.6rem;
}

.page-header__description {
  @include use-responsive-sizing(
    font-size,
    (
      xs: 2rem,
      s: 2.4rem,
      m: 2.8rem,
      l: 3.2rem,
      xl: 3.6rem,
    )
  );

  font-family: var(--font-stack-dosis);
  line-height: var(--line-height-small);
  margin-top: 1.6rem;
  text-transform: uppercase;
}

.page-header__copyright {
  bottom: 0;
  position: absolute;
  right: 0;

  @include mq.mq($until: l) {
    &:not(:last-child) {
      align-self: flex-end;
      position: relative;
    }
  }
}

.page-header__box {
  @include use-responsive-sizing(padding, $gaps);

  background-color: #fff;
  width: 100%;

  @include mq.mq($from: l) {
    flex-shrink: 0;
    width: column-width(6);
  }
}

.page-header__content + .page-header__box,
.page-header__content + .page-header__copyright {
  @include mq.mq($until: l) {
    margin-top: 2.4rem;
  }
}

.page-header__content + .page-header__box,
.page-header__content + .page-header__copyright + .page-header__box {
  @include use-responsive-sizing(margin-left, $gaps, l);
}
