@use 'sass:color';

.button {
  @include use-responsive-config(--button-font-size, (xs 1.6rem, m 2rem));
  @include use-responsive-config(--button-line-height, (xs 2rem, m 2.4rem));
  @include use-responsive-config(--button-padding-block, (xs 1.2rem, m 1.6rem));
  @include use-responsive-config(--button-padding-inline, (xs 2.4rem, m 3.2rem));

  --_button-content-opacity: var(--button-content-opacity, 1);

  align-items: center;
  background-color: var(--button-background-color, var(--color-blue));
  border-radius: 0.5rem;
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.2);
  color: var(--button-color, var(--color-white));
  display: inline-flex;
  font-size: var(--_button-font-size);
  font-weight: bold;
  justify-content: center;
  line-height: var(--_button-line-height);
  padding-block: var(--_button-padding-block);
  padding-inline: var(--_button-padding-inline);
  transition-property: background-color, box-shadow, color;
  user-select: none;

  &:not([disabled]):hover,
  &:not(.u-invisible-focus):focus {
    background-color: var(--button-background-color--enter, var(--color-black));
    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.3);
    color: var(--button-color--enter, var(--color-white));
  }

  &.is-requesting,
  &[disabled] {
    --_button-content-opacity: var(--button-content-opacity--disabled, 0.8);

    background-color: color-mix(in srgb, var(--button-background-color, var(--color-blue)) 70%, #fff);
    cursor: not-allowed;
    pointer-events: none;
  }
}

.button--secondary {
  background-color: var(--button-secondary-background-color, var(--color-yellow));
  color: var(--button-secondary-color, var(--color-black));

  &:not([disabled]):hover,
  &:not(.u-invisible-focus):focus {
    background-color: var(--button-secondary-background-color--enter, var(--color-black));
    color: var(--button-secondary-color--enter, var(--color-white));
  }

  &.is-requesting,
  &[disabled] {
    --_button-content-opacity: var(--button-secondary-content-opacity--disabled, 0.5);

    background-color: color-mix(in srgb, var(--button-secondary-background-color, var(--color-yellow)) 70%, #fff);
  }
}

.button--icon-only {
  border-radius: 50%;
  padding: 1.6rem;
}

.button--block {
  display: flex;
  width: 100%;
}

.button__icon {
  --_button-icon-size: var(--button-icon-size, 2.4rem);

  font-size: var(--_button-icon-size);
  line-height: 0;
  margin-right: calc(var(--_button-icon-size) / 2);

  .button--icon-only & {
    margin-right: 0;
  }
}

.button__icon,
.button__text {
  opacity: var(--_button-content-opacity);
  pointer-events: none;
  transition-property: opacity;
}
