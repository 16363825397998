:root {
  @include use-responsive-sizing(--gallery-padding, responsive-map(xs 2rem, xl 3rem));

  --gallery-color: #fff;
  --gallery-background-color: rgba(0, 0, 0, 0.8);
  --gallery-line-height: var(--line-height-body);
  --gallery-close-icon-size: 1.6rem;
  --gallery-navigation-button-icon-size: 2.4rem;
}

.gallery__box {
  overflow: hidden;
  position: relative;
}

.gallery__preview {
  position: relative;
  z-index: 1;
}

.gallery__button {
  --overlay-link-inset: -100vw;
  inset-block-start: 2rem;
  inset-inline-end: 2rem;

  position: absolute;
  z-index: 2;
}

.gallery__overlay {
  --gallery-close-icon-button-height: var(--gallery-close-icon-size) + 1.6rem * 2;
  --gallery-navigation-icon-button-height: var(--gallery-navigation-button-icon-size) + 1.6rem * 2;

  animation-duration: var(--duration-fast);
  animation-name: opacity;
  backdrop-filter: blur(10px);
  background-color: var(--gallery-background-color);
  color: var(--gallery-color);
  display: flex;
  flex-direction: column;
  inset: 0;
  overflow: hidden;
  padding-block-end: calc(var(--gallery-padding) + var(--gallery-navigation-icon-button-height) + 2rem);
  padding-block-start: calc(var(--gallery-padding) + var(--gallery-close-icon-button-height) + 2rem);
  padding-inline: var(--gallery-padding);
  position: fixed;
  z-index: z-index('overlay');

  &:is([aria-hidden='true']) {
    display: none;
  }
}

.gallery__overlay-inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.gallery__images {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  position: relative;
  width: 100%;
  z-index: 1;
}

.gallery__image {
  --image-z-index: 1;

  animation-duration: var(--duration-x-long);
  animation-name: opacity;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  margin-block: auto;
  position: relative;
  width: 100%;

  &:is([data-move-direction='left']) {
    animation-name: opacity, fade-in-from-left;
  }

  &:is([data-move-direction='right']) {
    animation-name: opacity, fade-in-from-right;
  }
}

.gallery__copyright {
  inset-block-start: 0;
  inset-inline-end: 0;
  line-height: 0;
  position: absolute;
  z-index: 2;
}

.gallery__caption {
  --link-color: var(--gallery-color);
  --link-color--enter: var(--gallery-color);

  backdrop-filter: blur(10px);
  background-color: rgba(#000, 0.6);
  border-radius: 0.5rem;
  font-size: 1.2rem;
  inset-block-end: 1rem;
  inset-inline-start: 1rem;
  line-height: var(--gallery-line-height);
  max-width: calc(100% - 3rem);
  padding: 0.5rem 1rem;
  position: absolute;
  z-index: 2;
}

.gallery__image--hidden {
  display: none;
}

.gallery__controls {
  inset: 0;
  pointer-events: none;
  position: absolute;
  user-select: none;
  z-index: 2;
}

.gallery__close {
  --button-icon-size: var(--gallery-close-icon-size);

  inset-block-start: var(--gallery-padding);
  inset-inline-end: var(--gallery-padding);
  line-height: 0;
  pointer-events: all;
  position: absolute;
}

.gallery__position {
  font-size: 1.6rem;
  font-weight: var(--font-weight-bold);
  inset-block-start: var(--gallery-padding);
  inset-inline-start: var(--gallery-padding);
  line-height: var(--gallery-close-icon-button-height);
  position: absolute;
}

.gallery__navigation {
  --button-icon-size: var(--gallery-navigation-button-icon-size);

  display: flex;
  gap: 1rem;
  inset-block-end: var(--gallery-padding);
  inset-inline-start: 50%;
  pointer-events: all;
  position: absolute;
  transform: translateX(-50%);
}
