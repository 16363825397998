.list {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: var(--line-height-default);
}

.list__item {
  border-bottom: 1px solid var(--color-offset-blue);
  border-top: 1px solid var(--color-offset-blue);
  display: block;
  position: relative;

  &:not(:first-child) {
    margin-top: -1px;
  }
}

.list__link {
  display: block;
  padding-bottom: 2.4rem;
  padding-right: 4rem;
  padding-top: 2.4rem;
}

.list__icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.list__load-more {
  display: block;
  margin-top: 3.2rem;
  text-align: center;
}
