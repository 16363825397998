.form-button {
  display: block;
  padding: 0.8rem;
}

.form-button__button {
  background-color: var(--color-offset-blue);
  border-radius: 0.3rem;
  font-size: 1.4rem;
  height: 3.2rem;
  line-height: 1;
  padding: 0.9rem 1.2rem;
  position: relative;
  user-select: none;
}

.form-button__icon {
  font-size: 1rem;
  line-height: 0;
  margin-left: 0.4rem;
  vertical-align: -0.05rem;
}
