.breadcrumb {
  font-size: 1.4rem;
  line-height: var(--line-height-default);
  padding-bottom: 1.2rem;
  padding-top: 1.2rem;
}

.breadcrumb__items {
  @include use-container();

  display: block;
}

.breadcrumb__item {
  display: inline;
  font-weight: bold;
}

.breadcrumb__item--last {
  font-weight: normal;
}

.breadcrumb__icon {
  font-size: 0.9rem;
  line-height: 0;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
