@use 'mq';

.box {
  > * + * {
    margin-top: 3.2rem;
  }
}

.box__text {
  font-family: var(--font-stack-dosis);
  font-size: 2rem;
  font-weight: bold;
  line-height: var(--line-height-default);

  @include mq.mq($from: m) {
    font-size: 2.2rem;
  }

  @include mq.mq($from: l) {
    font-size: 2.4rem;
  }

  @include mq.mq($from: xl) {
    font-size: 2.6rem;
  }
}

.box__more-link {
  display: block;

  & + & {
    margin-top: 0.8rem;
  }
}
