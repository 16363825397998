.alert {
  background-color: var(--color-white);
  border-radius: 0.5rem;
  box-shadow: inset 0 0 0 0.1rem var(--color-gray);
  display: flex;
  padding: 2rem;
}

.alert__icon {
  flex-shrink: 0;
  font-size: 4rem;
  line-height: 0;
  margin-right: 1.6rem;

  .alert--info & {
    color: var(--color-blue);
  }

  .alert--success & {
    color: var(--color-green);
  }

  .alert--warning & {
    color: var(--color-dark-yellow);
  }

  .alert--error & {
    color: var(--color-red);
  }
}

.alert__text {
  align-self: center;
  font-size: 1.8rem;
  line-height: var(--line-height-default);
}
