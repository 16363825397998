@use 'sass:map';
@use 'sass:math';

// Root
$root-font-size-px: 10px;
$root-min-width: 360px;

// Z-Index
$z-index-list: (
  'overlay',
  'skip-link',
  'site-banner',
  'loading',
  'flyout',
  'overlay-header',
  'header',
  'breadcrumb',
  'default'
);

// Number of columns the grid system has
$columns: 12;

// Gaps between grid columns
$gaps: (
  xs: 3rem,
  s: 3rem,
  m: 3.5rem,
  l: 4rem,
  xl: 5rem,
);

// List of breakpoints
// stylelint-disable length-zero-no-unit
$breakpoints: (
  xs: 0px,
  s: 600px,
  m: 768px,
  l: 1024px,
  xl: 1440px,
);
// stylelint-enable

// Inner container padding (acts like body padding)
$inner-container-padding: (
  xs: 3vw,
  s: 4vw,
  m: 5vw,
  l: 6vw,
  xl: 7vw,
);

// Max width of the inner container
$inner-container-max-width: 144rem;

// Calculated value of the minium and maximum padding of the inner container
$inner-container-min-padding: math.div(map.get($inner-container-padding, xs), 100vw) * $root-min-width;
$inner-container-max-padding: math.div(map.get($inner-container-padding, xl), 100vw) * $inner-container-max-width;

// Calculated value of inner width
$inner-container-max-inner-width: $inner-container-max-width - $inner-container-max-padding * 2;

// Padding for content containers
$content-container-paddings: (
  xs: 4.8rem,
  s: 5.2rem,
  m: 5.6rem,
  l: 6.4rem,
  xl: 8rem,
);

// Configure mq
@use 'mq' with (
  $breakpoints: $breakpoints
);
