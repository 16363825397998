@use 'mq';

.stage {
  @include use-responsive-sizing(padding-bottom, $inner-container-padding, xs, m, true);

  background-color: var(--color-light-blue);
  position: relative;
}

.stage__image {
  @include use-responsive-sizing(
    height,
    (
      xs: 45rem,
      s: 54rem,
      m: 59rem,
      l: 67rem,
      xl: 80rem,
    )
  );
  --image-z-index: 1;

  display: flex;
  flex-direction: column;
  position: relative;

  &::after {
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    inset: 0;
    position: absolute;
    z-index: 2;
  }
}

.stage__image-copyright {
  @include use-responsive-sizing(right, $inner-container-padding, xs, m, true);
  @include use-responsive-sizing(
    bottom,
    (
      xs: 15rem,
      l: 0,
    )
  );

  position: absolute;
  right: 0;
  z-index: 3;
}

.stage__stage {
  pointer-events: none;

  @include mq.mq($from: l) {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 10rem;
  }

  @include mq.mq($from: xl) {
    top: 11rem;
  }
}

.stage__inner {
  @include use-container();

  margin-top: -15rem;
  position: relative;
  z-index: 2;

  @include mq.mq($from: l) {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    margin-top: 0;
  }
}

.stage__case-teaser {
  @include use-responsive-sizing(
    width,
    (
      l: column-width(6),
      xl: column-width(6),
    )
  );

  flex-shrink: 0;
  pointer-events: all;
}

.stage__arrow {
  display: none;

  @include mq.mq($from: l) {
    color: var(--color-white);
    display: block;
    font-size: 4rem;
    inset-block-end: 2rem;
    inset-inline-start: 50%;
    padding-block-start: 0;
    position: absolute;
    transform: translateX(-50%);
    z-index: 5;
  }
}
