@use 'mq';

:root {
  --case-teaser-aspect-ratio: auto;
}

.case-teaser {
  aspect-ratio: var(--case-teaser-aspect-ratio);
  background-color: #fff;
  position: relative;
  width: 100%;
}

.case-teaser__image {
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;

  &::after {
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    inset: 0;
    position: absolute;
    z-index: 1;
  }
}

.case-teaser__inner {
  @include use-responsive-sizing(padding, $gaps);

  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  z-index: 1;

  .case-teaser__image + & {
    color: #fff;
  }
}

.case-teaser__meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.6rem;
}

.case-teaser__kicker {
  font-family: var(--font-stack-dosis);
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.case-teaser__law-articles {
  margin-left: auto;
}

.case-teaser__content {
  margin-bottom: auto;
  margin-top: auto;
}

.case-teaser__headline {
  .case-teaser--with-image & {
    --headline-color: var(--color-white);
    --headline-highlight-color: var(--color-white);
  }
}

.case-teaser__description {
  font-family: var(--font-stack-dosis);
  font-size: 2rem;
  line-height: var(--line-height-small);
  margin-top: 1.6rem;

  @include mq.mq($from: m) {
    font-size: 2.2rem;
  }

  @include mq.mq($from: l) {
    font-size: 2.4rem;
  }

  @include mq.mq($from: xl) {
    font-size: 2.6rem;
  }
}

.case-teaser__button {
  display: inline-block;
  margin-top: 3.2rem;
}

.case-teaser__more-link {
  --overlay-link-inset: -2rem;

  align-self: flex-end;
  margin-top: 4rem;
  position: relative;
  text-align: right;
}
