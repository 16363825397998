.slider {
  position: relative;
}

.slider__track {
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;

  &:focus {
    outline: 0;
  }

  &:focus::after {
    opacity: 1;
  }

  &:focus:not(:focus-visible)::after {
    opacity: 0;
  }

  &::after {
    @include use-focus-outline();

    --focus-outline-color: var(--color-white);
    --focus-outline-offset: -1rem;

    content: '';
    inset: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    position: none;
    z-index: 5;
  }
}

.slider__slide {
  flex-shrink: 0;
  position: relative;
  width: 100%;
}

.slider__image {
  display: flex;
  flex-direction: column;
  inset: 0;
  position: absolute;
  z-index: 1;

  &::after {
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    inset: 0;
    position: absolute;
    z-index: 2;
  }
}

.slider__image-copyright {
  bottom: 0;
  position: absolute;
  right: 0;
  z-index: 3;
}

.slider__content {
  @include use-responsive-sizing(
    min-height,
    (
      xs: 45rem,
      s: 50.5rem,
      m: 54rem,
      l: 59rem,
      xl: 67rem,
    )
  );

  height: 100%;
  padding-bottom: 6.1rem;
  padding-top: 4rem;
  pointer-events: none;
  position: relative;
  z-index: 2;
}

.slider__inner {
  @include use-container();

  align-items: center;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.slider__headline {
  --headline-color: var(--color-white);
  --headline-highlight-color: var(--color-white);
}

.slider__image,
.slider__headline,
.slider__description,
.slider__button {
  pointer-events: all;
}

.slider__headline {
  width: calc(100% - 5rem);
}

.slider__description {
  width: calc(100% - 7rem);
}

.slider__headline,
.slider__description {
  @include use-responsive-sizing(
    width,
    (
      m: column-width(10),
      l: column-width(10),
      xl: column-width(8),
    )
  );
}

.slider__description {
  @include use-responsive-sizing(
    font-size,
    (
      xs: 2rem,
      s: 2.4rem,
      m: 2.8rem,
      l: 3.2rem,
      xl: 3.6rem,
    )
  );

  font-family: var(--font-stack-dosis);
  line-height: var(--line-height-small);
  margin-top: 1.6rem;
  text-transform: uppercase;
}

.slider__button {
  margin-top: 3.2rem;
}

.slider__navigation {
  align-items: center;
  display: flex;
  inset: 0;
  justify-content: space-between;
  pointer-events: none;
  position: absolute;
  z-index: 3;
}

.slider__navigation-indicators {
  align-self: flex-end;
  display: flex;
  padding-bottom: 2.4rem;
}

.slider__navigation-indicator {
  background-color: var(--color-white);
  border-radius: 50%;
  height: 1.3rem;
  opacity: 0.3;
  pointer-events: all;
  transition-property: opacity;
  width: 1.3rem;

  & + & {
    margin-left: 1.3rem;
  }

  &[disabled] {
    pointer-events: none;
  }

  &[disabled],
  &:hover,
  &:focus {
    opacity: 1;
    outline-color: var(--color-white);
  }
}

.slider__navigation-button {
  color: var(--color-white);
  font-size: 4.8rem;
  line-height: 0;
  padding: 1.6rem;
  pointer-events: all;
  transition-property: background-color, opacity;

  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }

  &:not([disabled]):hover,
  &:focus {
    background-color: var(--color-black);
  }
}
