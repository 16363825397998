:root {
  --figure-background-color: var(--color-light-blue);
  --figure-caption-min-height: 0;
  --figure-height: auto;
}

.figure {
  display: block;
}

.figure__figure {
  align-items: center;
  background-color: var(--figure-background-color);
  display: flex;
  height: var(--figure-height);
  justify-content: center;
  position: relative;
  z-index: 1;
}

.figure__caption {
  min-height: var(--figure-caption-min-height);
  position: relative;
}

.figure__caption-text {
  font-family: var(--font-stack-dosis);
  font-size: 1.6rem;
  font-weight: bold;
  line-height: var(--line-height-default);
  padding-top: 0.8rem;
  text-align: left;
}

.figure__copyright {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-100%);
  z-index: 2;
}
