.field-table__label {
  color: var(--color-black);
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 1.6rem;
  width: 100%;
}

.field-table__table {
  border: 1px solid var(--color-black);
  margin-bottom: 1.6rem;
  width: 100%;
}

.field-table__table-cell {
  border: 1px solid var(--color-black);
  padding: 0.5rem 1rem;
  text-align: left;
  vertical-align: middle;
}

.field-table__table-cell--head {
  background-color: var(--color-black);
  color: #fff;
  font-weight: bold;
  padding: 1rem;
}

.field-table__table-cell--action {
  width: 2.6rem;
}

.field-table__add {
  text-align: right;
}

.field-table__description {
  display: flex;
  font-size: 1.6rem;
  line-height: var(--line-height-default);
  margin-top: 1.2rem;
}

.field-table__description-icon {
  flex-shrink: 0;
  margin-right: 0.8rem;
  margin-top: 0.5rem;
}
