@use 'mq';

.breaker {
  @include use-responsive-sizing(
    padding-top padding-bottom,
    (
      xs: 4.8rem,
      s: 7.2rem,
      m: 8.8rem,
      l: 10.4rem,
      xl: 14.4rem,
    )
  );

  background-color: var(--color-white);
  position: relative;
}

.breaker__image {
  display: flex;
  flex-direction: column;
  height: 100%;
  inset: 0 0 auto;
  max-height: 45rem;
  position: absolute;
  z-index: 1;

  &::after {
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    inset: 0;
    position: absolute;
    z-index: 1;
  }

  @include mq.mq($from: m) {
    max-height: none;
  }
}

.breaker__inner {
  @include use-container();

  @include mq.mq($from: m) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.breaker__content {
  align-items: center;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 7.2rem;
  padding-top: 2.4rem;
  text-align: center;

  @include mq.mq($from: s) {
    padding-top: 0;
  }

  @include mq.mq($from: m) {
    padding-bottom: 0;
  }
}

.breaker__headline {
  --headline-color: var(--color-white);
  --headline-highlight-color: var(--color-white);
}

.breaker__description {
  @include use-responsive-sizing(
    font-size,
    (
      xs: 2rem,
      s: 2.4rem,
      m: 2.8rem,
      l: 3.2rem,
      xl: 3.6rem,
    )
  );

  font-family: var(--font-stack-dosis);
  line-height: var(--line-height-small);
  margin-top: 1.6rem;
  text-transform: uppercase;
}

.breaker__copyright {
  position: relative;
  text-align: right;
  z-index: 2;

  @include mq.mq($from: m) {
    bottom: 0;
    position: absolute;
    right: 0;
  }
}

.breaker__box {
  @include use-responsive-sizing(padding, $gaps);

  background-color: var(--color-white);
}

.breaker__content,
.breaker__box {
  position: relative;
  z-index: 2;

  @include mq.mq($from: m) {
    flex-shrink: 0;
    width: column-width(6);
  }
}

.breaker__content + .breaker__box,
.breaker__content + .breaker__copyright + .breaker__box {
  @include use-responsive-sizing(margin-left, $gaps, m);
}
