@use 'mq';

.quote {
  text-align: center;
}

.quote__icon {
  font-size: 4rem;
  line-height: 0;
  margin-bottom: 2rem;

  @include mq.mq($from: s) {
    font-size: 4.2rem;
  }

  @include mq.mq($from: m) {
    font-size: 4.4rem;
  }

  @include mq.mq($from: l) {
    font-size: 4.8rem;
  }

  @include mq.mq($from: xl) {
    font-size: 5rem;
  }
}

.quote__quote {
  font-family: var(--font-stack-dosis);
  font-size: 2.6rem;
  line-height: var(--line-height-small);

  @include mq.mq($from: s) {
    font-size: 2.8rem;
  }

  @include mq.mq($from: m) {
    font-size: 3rem;
  }

  @include mq.mq($from: l) {
    font-size: 3.2rem;
  }

  @include mq.mq($from: xl) {
    font-size: 3.5rem;
  }
}

.quote__caption {
  font-size: 1.4rem;
  margin-top: 2.4rem;
}
