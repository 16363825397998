:root {
  @include use-responsive-sizing(--logo-wall-gap, $gaps);

  --logo-wall-font-size: 1.6rem;
  --logo-wall-text-gap: 1rem;
  --logo-wall-logo-padding: 2rem;
  --logo-wall-logo-background: #{var(--color-white)};
  --logo-wall-line-height: #{var(--line-height-default)};
  --logo-wall-max-width: 20rem;
  --logo-wall-max-height: 10rem;
}

.logo-wall {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--logo-wall-gap);
  justify-content: center;
}

.logo-wall--2 {
  @include use-responsive-sizing(
    --logo-wall-columns,
    (
      s: 2,
    )
  );

  --logo-wall-max-width: 30rem;
  --logo-wall-max-height: 15rem;
}

.logo-wall--3 {
  @include use-responsive-sizing(
    --logo-wall-columns,
    (
      s: 2,
      m: 3,
    )
  );
}

.logo-wall--4 {
  @include use-responsive-sizing(
    --logo-wall-columns,
    (
      s: 2,
      m: 4,
    )
  );
}

.logo-wall--6 {
  @include use-responsive-sizing(
    --logo-wall-columns,
    (
      s: 2,
      m: 4,
      l: 6,
    )
  );
}

.logo-wall__logo {
  align-items: center;
  background-color: var(--logo-wall-logo-background);
  display: flex;
  flex-basis: calc((100% - var(--logo-wall-gap) * (var(--logo-wall-columns) - 1)) / var(--logo-wall-columns));
  flex-direction: column;
  flex-shrink: 0;
  gap: var(--logo-wall-text-gap);
  justify-content: center;
  padding: var(--logo-wall-logo-padding);
  transition-property: transform;

  &:any-link:is(:hover, :focus-visible) {
    transform: scale(1.05);
  }
}

.logo-wall__logo-text {
  font-size: var(--logo-wall-font-size);
  line-height: var(--logo-wall-line-height);
  text-align: center;
}

.logo-wall__logo-image {
  height: auto;
  max-height: var(--logo-wall-max-height);
  max-width: var(--logo-wall-max-width);
  object-fit: contain;
  width: 100%;
}
